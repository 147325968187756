import { useState } from "react";
import { unFollowAccount, followAccount } from "~/utils/transactions";
import { useScotFollowing } from "./contentHooks"; // Adjust the import path as needed

const useFollow = (activeAccount: string, user: string) => {
  const { isFollowing, setIsFollowing } = useScotFollowing({
    follower: activeAccount || "",
    account: user || "",
    enabled: !!activeAccount
  });

  const [followingSpinner, setFollowingSpinner] = useState(false);

  const handleFollow = async (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();

    if (!activeAccount) return;

    try {
      setFollowingSpinner(true);

      if (isFollowing) {
        await unFollowAccount(activeAccount, user);
        setIsFollowing(false);
      } else {
        await followAccount(activeAccount, user);
        setIsFollowing(true);
      }
    } catch {
      // handle error if needed
    } finally {
      setFollowingSpinner(false);
    }
  };

  return { isFollowing, followingSpinner, handleFollow };
};

export default useFollow;
