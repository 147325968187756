import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import useFollow from "../../hooks/useFollow";

interface FollowButton {
  activeAccount: string;
  user: string;
}

const FollowButton = ({ activeAccount, user }: FollowButton) => {
  const { isFollowing, followingSpinner, handleFollow } = useFollow(
    activeAccount,
    user
  );

  return (
    <button
      type="button"
      className={classNames(
        "relative flex justify-center items-center py-1 px-5 h-fit min-h-[38px] min-w-[76px] rounded-full text-xs font-semibold hover:opacity-80 transition-opacity duration-150 z-10 disabled:opacity-50 disabled:pointer-events-none",
        {
          "bg-pri-d dark:bg-pri text-pri-d dark:text-pri": !isFollowing,
          "bg-gray-300 dark:bg-zinc-700 text-pri/80 dark:text-pri-d/80":
            isFollowing
        }
      )}
      onClick={event => handleFollow(event)}
      disabled={followingSpinner}
    >
      {followingSpinner ? (
        <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" />
      ) : isFollowing ? (
        "Unfollow"
      ) : (
        "Follow"
      )}
    </button>
  );
};

export default FollowButton;
